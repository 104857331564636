import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Grid, Tab } from "@mui/material"
import { useAtom } from "jotai"
import { useSnackbar } from "notistack"
import { SyntheticEvent, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { CeoView, DpoView, OtherContactView } from "src/features/account"
import { ApiError } from "src/shared/client"
import { merchantDataAtom, updateMerchantDataAtom } from "src/shared/stores"
import { filterFormData } from "src/widgets/account"

const ContactDataBox = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "accountPage"
  })
  const { enqueueSnackbar } = useSnackbar()
  const { handleSubmit } = useFormContext<MerchantRecord>()
  const [{ mutateAsync: updateMerchantData }] = useAtom(updateMerchantDataAtom)
  const [{ data: merchantData, refetch: refetchMerchantData }] =
    useAtom(merchantDataAtom)

  const [tabPage, setTabPage] = useState("1")
  const handleTabChange = (_event: SyntheticEvent, newTabPage: string) => {
    setTabPage(newTabPage)
  }

  const handleUpdateMerchant = (e: React.BaseSyntheticEvent) =>
    handleSubmit(
      async (formData) => {
        try {
          await updateMerchantData({
            updatedMerchantData: filterFormData(formData)
          })
          refetchMerchantData()
          enqueueSnackbar({
            variant: "success",
            message: t("merchantUpdateSuccessSnackbar")
          })
        } catch (error) {
          if (error instanceof ApiError) {
            const response: ServiceError = await error.errorResponse.json()

            enqueueSnackbar({
              variant: "detailedSnackbar",
              message: t(response.code, { keyPrefix: "errorCodes" }),
              details: response.message,
              autoHideDuration: null
            })
          }
        }
      },
      (errors) => {
        console.error(errors)
      }
    )(e)

  return (
    <Grid xs={12} item className="McpTabs" margin={2} alignItems="flex-start">
      <TabContext value={tabPage}>
        <Box className="McpTabsContext">
          <TabList
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons={false}
            className="borderBottom"
          >
            <Tab label={t("contact.ceoTab.title")} value="1" />
            <Tab label={t("contact.dpoTab.title")} value="2" />
            <Tab label={t("contact.otherTab.title")} value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <CeoView
            handleUpdateMerchant={handleUpdateMerchant}
            merchantData={merchantData}
          />
        </TabPanel>
        <TabPanel value="2">
          <DpoView handleUpdateMerchant={handleUpdateMerchant} />
        </TabPanel>
        <TabPanel value="3">
          <OtherContactView handleUpdateMerchant={handleUpdateMerchant} />{" "}
        </TabPanel>
      </TabContext>
    </Grid>
  )
}

export default ContactDataBox
