import {
  KeyboardArrowRightRounded,
  Visibility,
  VisibilityOff
} from "@mui/icons-material"
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput
} from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  authenticationService,
  UpdatePasswordParameters
} from "src/shared/authentication"

const PasswordChangeView = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "accountPage.account-data.changePasswordTab"
  })
  const { enqueueSnackbar } = useSnackbar()

  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [repeatNewPassword, setRepeatNewPassword] = useState("")
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showRepeatNewPassword, setShowRepeatNewPassword] = useState(false)
  const [passwordsEqualError, setPasswordsEqualError] = useState(false)
  const [repeatPasswordNotEqualError, setRepeatPasswordNotEqualError] =
    useState(false)

  const { mutate: updatePassword, isPending: isUpdatingPassword } = useMutation<
    unknown,
    Error,
    UpdatePasswordParameters
  >({
    mutationFn: ({
      currentPassword: oldPassword,
      newPassword
    }: UpdatePasswordParameters) =>
      authenticationService.updatePassword({
        currentPassword: oldPassword,
        newPassword
      })
  })

  const handleChangePasswordFormSubmit = () => {
    updatePassword(
      { currentPassword: oldPassword, newPassword },
      {
        onSuccess: () => {
          setOldPassword("")
          setNewPassword("")
          setRepeatNewPassword("")
          setShowNewPassword(false)
          setShowOldPassword(false)
          setShowRepeatNewPassword(false)
          enqueueSnackbar({
            variant: "success",
            message: t("changePasswordSuccessSnackbar")
          })
        },
        onError: (error) => {
          enqueueSnackbar({
            variant: "detailedSnackbar",
            message: t(error.name, { keyPrefix: "errorCodes.auth" }),
            autoHideDuration: null,
            details: error.message
          })
        }
      }
    )
  }

  useEffect(() => {
    if (
      repeatNewPassword.length > 0 &&
      newPassword.length > 0 &&
      repeatNewPassword !== newPassword
    ) {
      setRepeatPasswordNotEqualError(true)
    } else {
      setRepeatPasswordNotEqualError(false)
    }
  }, [repeatNewPassword, newPassword])

  useEffect(() => {
    if (
      oldPassword.length > 0 &&
      newPassword.length > 0 &&
      newPassword === oldPassword
    ) {
      setPasswordsEqualError(true)
    } else {
      setPasswordsEqualError(false)
    }
  }, [oldPassword, newPassword])

  return (
    <Box
      className="McpForm"
      component="form"
      onSubmit={(e) => {
        e.preventDefault()
        handleChangePasswordFormSubmit()
      }}
    >
      <Grid container item spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl variant="outlined">
            <FormLabel required>{t("oldPassword")}</FormLabel>
            <OutlinedInput
              data-testid="oldPasswordInput"
              autoFocus
              value={oldPassword}
              size="small"
              required
              name="oldPassword"
              type={showOldPassword ? "text" : "password"}
              id="oldPassword"
              onChange={(e) => setOldPassword(e.target.value)}
              autoComplete="old-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                    edge="end"
                  >
                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl variant="outlined">
              <FormLabel required>{t("newPassword")}</FormLabel>
              <OutlinedInput
                data-testid="newPasswordInput"
                value={newPassword}
                size="small"
                required
                name="newPassword"
                type={showNewPassword ? "text" : "password"}
                id="newPassword"
                onChange={(e) => setNewPassword(e.target.value)}
                autoComplete="new-password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <small
              data-testid="alertEqualPasswords"
              style={{
                color: "red",
                visibility: !passwordsEqualError ? "hidden" : "initial"
              }}
            >
              {t("equalPasswords")}
            </small>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl variant="outlined">
              <FormLabel required>{t("repeatNewPassword")}</FormLabel>
              <OutlinedInput
                value={repeatNewPassword}
                data-testid="repeatNewPasswordInput"
                size="small"
                required
                name="repeatNewPassword"
                type={showRepeatNewPassword ? "text" : "password"}
                id="password"
                onChange={(e) => setRepeatNewPassword(e.target.value)}
                autoComplete="new-password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowRepeatNewPassword(!showRepeatNewPassword)
                      }
                      edge="end"
                    >
                      {showRepeatNewPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <small
              data-testid="alertNotEqualPasswords"
              style={{
                color: "red",
                visibility: !repeatPasswordNotEqualError ? "hidden" : "initial"
              }}
            >
              {t("notEqualRepeatPassword")}
            </small>
          </Grid>

          <Grid container item>
            <Button
              data-testid="changePasswordBtn"
              type="submit"
              variant="contained"
              disabled={
                isUpdatingPassword ||
                repeatPasswordNotEqualError ||
                passwordsEqualError ||
                oldPassword === "" ||
                newPassword === "" ||
                repeatNewPassword === ""
              }
            >
              {t("saveBtn", { keyPrefix: "common" })}
              <KeyboardArrowRightRounded fontSize="small" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PasswordChangeView
