import {
  FactCheckOutlined,
  GradingOutlined,
  HouseOutlined,
  MonetizationOnOutlined,
  PeopleAltOutlined,
  PersonOutlined
} from "@mui/icons-material"
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material"
import { useAtom } from "jotai"
import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { Navlink, useCustomMediaQuery } from "src/shared/components"
import { buildAccountSubpageUrl } from "src/shared/routing"
import { merchantDataAtom } from "src/shared/stores"
import {
  AccountDataBox,
  BankAccountDataBox,
  CompanyDataBox,
  ContactDataBox
} from "src/widgets/account"

const AccountPage = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "accountPage"
  })

  const location = useLocation()
  const navigate = useNavigate()
  const isSmallScreen = useCustomMediaQuery("(max-width: 899px)")

  const [{ data: merchantData }] = useAtom(merchantDataAtom)
  const useFormMethods = useForm({
    values: merchantData,
    mode: "all"
  })

  const [currentAccountRoute, setCurrentAccountRoute] = useState<string>("")
  const [currentAccountSubRoute, setCurrentAccountSubRoute] =
    useState<string>("account-data")

  const handleSelectChange = (e: SelectChangeEvent) => {
    setCurrentAccountRoute(e.target.value)
    navigate(e.target.value)
  }

  useEffect(() => {
    if (location) {
      setCurrentAccountRoute(location.pathname)
      setCurrentAccountSubRoute(location.pathname.replace("/account/", ""))
    }
  }, [location])

  const accountPageComponent: {
    [key: string]: JSX.Element
  } = {
    "account-data": <AccountDataBox />,
    company: <CompanyDataBox />,
    contact: <ContactDataBox />,
    "bank-account": <BankAccountDataBox />,
    legal: <div>legal</div>,
    dpa: <div>data protection</div>
  }

  return (
    <FormProvider {...useFormMethods}>
      <Grid container spacing={3}>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("pageHeadline")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              {t("pageSubheadline", {
                merchantId: merchantData?.merchantId
              })}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} alignItems={"stretch"}>
          {isSmallScreen ? (
            <Grid container item xs={12} marginBottom={2}>
              <Select
                fullWidth
                data-testid={"accountLink-select"}
                value={currentAccountRoute}
                onChange={handleSelectChange}
              >
                <MenuItem
                  data-testid={"select-accountData"}
                  value={buildAccountSubpageUrl("accountData")}
                >
                  {t("navigation.account-data")}
                </MenuItem>
                <MenuItem
                  data-testid={"select-company"}
                  value={buildAccountSubpageUrl("company")}
                >
                  {t("navigation.company")}
                </MenuItem>
                <MenuItem
                  data-testid={"select-contact"}
                  value={buildAccountSubpageUrl("contact")}
                >
                  {t("navigation.contact")}
                </MenuItem>
                <MenuItem
                  data-testid={"select-bankAccount"}
                  value={buildAccountSubpageUrl("bankAccount")}
                >
                  {t("navigation.bank-account")}
                </MenuItem>
                <MenuItem
                  data-testid={"select-legal"}
                  value={buildAccountSubpageUrl("legal")}
                >
                  {t("navigation.legal")}
                </MenuItem>
                <MenuItem
                  data-testid={"select-dpa"}
                  value={buildAccountSubpageUrl("dpa")}
                >
                  {t("navigation.dpa")}
                </MenuItem>
              </Select>
            </Grid>
          ) : (
            <Grid container item xs={12} md={5} lg={4} xl={3}>
              <List>
                <ListItem key={"accountLink-accountData"}>
                  <ListItemButton
                    component={Navlink}
                    isNested={false}
                    to={buildAccountSubpageUrl("accountData")}
                    data-testid={"accountLink-accountData"}
                  >
                    <ListItemIcon>
                      <PersonOutlined />
                    </ListItemIcon>
                    <ListItemText primary={t("navigation.account-data")} />
                  </ListItemButton>
                </ListItem>

                <ListItem key={"accountLink-company"}>
                  <ListItemButton
                    component={Navlink}
                    isNested={false}
                    to={buildAccountSubpageUrl("company")}
                    data-testid={"accountLink-company"}
                  >
                    <ListItemIcon>
                      <HouseOutlined />
                    </ListItemIcon>
                    <ListItemText primary={t("navigation.company")} />
                  </ListItemButton>
                </ListItem>

                <ListItem key={"accountLink-contact"}>
                  <ListItemButton
                    component={Navlink}
                    isNested={false}
                    to={buildAccountSubpageUrl("contact")}
                    data-testid={"accountLink-contact"}
                  >
                    <ListItemIcon>
                      <PeopleAltOutlined />
                    </ListItemIcon>
                    <ListItemText primary={t("navigation.contact")} />
                  </ListItemButton>
                </ListItem>

                <ListItem key={"accountLink-bankAccount"}>
                  <ListItemButton
                    component={Navlink}
                    isNested={false}
                    to={buildAccountSubpageUrl("bankAccount")}
                    data-testid={"accountLink-bankAccount"}
                  >
                    <ListItemIcon>
                      <MonetizationOnOutlined />
                    </ListItemIcon>
                    <ListItemText primary={t("navigation.bank-account")} />
                  </ListItemButton>
                </ListItem>

                <ListItem key={"accountLink-legal"}>
                  <ListItemButton
                    component={Navlink}
                    isNested={false}
                    to={buildAccountSubpageUrl("legal")}
                    data-testid={"accountLink-legal"}
                  >
                    <ListItemIcon>
                      <FactCheckOutlined />
                    </ListItemIcon>
                    <ListItemText primary={t("navigation.legal")} />
                  </ListItemButton>
                </ListItem>

                <ListItem key={"accountLink-dpa"}>
                  <ListItemButton
                    component={Navlink}
                    isNested={false}
                    to={buildAccountSubpageUrl("dpa")}
                    data-testid={"accountLink-dpa"}
                  >
                    <ListItemIcon>
                      <GradingOutlined />
                    </ListItemIcon>
                    <ListItemText primary={t("navigation.dpa")} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={7}
            lg={8}
            xl={9}
            paddingY={0.5}
            className="McpBox"
          >
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom className="borderBottom">
                {t(`navigation.${currentAccountSubRoute}`)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {accountPageComponent[currentAccountSubRoute]}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default AccountPage
