import { Tooltip as MUITooltip, Zoom } from "@mui/material"
import { ReactElement } from "react"

const Tooltip = ({
  text,
  children
}: {
  text: string
  children: ReactElement
}) => (
  <MUITooltip
    title={text}
    placement="top"
    arrow
    className="McpTooltip"
    disableFocusListener
    disableTouchListener
    TransitionComponent={Zoom}
    slotProps={{
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -6]
            }
          }
        ]
      }
    }}
  >
    {children}
  </MUITooltip>
)

export default Tooltip
