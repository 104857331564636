import { ErrorOutline, KeyboardArrowRight } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography
} from "@mui/material"
import { isValidBIC, isValidIBAN } from "ibantools"
import { useAtom } from "jotai"
import { useSnackbar } from "notistack"
import { Controller, useFormContext } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { FormInputGroup, FormSelectGroup } from "src/features/onboarding"
import { ApiError } from "src/shared/client"
import { normalizeWhitespace } from "src/shared/functions"
import { useI18nCountries } from "src/shared/i18n"
import { merchantDataAtom, updateMerchantDataAtom } from "src/shared/stores"
import { filterFormData } from "src/widgets/account"

const BankAccountDataBox = () => {
  const { t } = useTranslation("translation")
  const { enqueueSnackbar } = useSnackbar()
  const { handleSubmit, getValues, control, unregister, watch } =
    useFormContext<MerchantRecord>()

  const [{ mutateAsync: updateMerchantData }] = useAtom(updateMerchantDataAtom)
  const [{ refetch: refetchMerchantData, isPending: isUpdatingMerchantData }] =
    useAtom(merchantDataAtom)

  const { possibleCountries } = useI18nCountries()

  const bankAccountInfo = {
    sepaType: { inputName: "bankAccount.type" },
    accountHolder: {
      inputName: "bankAccount.accountHolder",
      labelName: t("onboardingPage.accountFormData.accountHolder")
    },
    bic: {
      inputName: "bankAccount.bic",
      labelName: "BIC / SWIFT-Code",
      rules: {
        required: t("onboardingPage.accountFormData.bic.requiredErrorMessage"),
        validate: (value: string) =>
          isValidBIC(value) ||
          t("onboardingPage.accountFormData.bic.invalidBicErrorMessage")
      }
    },
    iban: {
      inputName: "bankAccount.iban",
      labelName: "IBAN",
      rules: {
        required: t("onboardingPage.accountFormData.iban.requiredErrorMessage"),
        validate: (value: string) =>
          isValidIBAN(value) ||
          t("onboardingPage.accountFormData.iban.invalidIbanErrorMessage")
      }
    },
    creditorIdentifier: {
      inputName: "bankAccount.creditorIdentifier",
      labelName: t("onboardingPage.accountFormData.creditorIdentifier")
    },
    mandateReference: {
      inputName: "bankAccount.mandateReference",
      labelName: t("onboardingPage.accountFormData.mandateReference")
    },
    hasDirectDebit: {
      inputName: "bankAccount.hasDirectDebitMandate",
      labelName: t(
        "onboardingPage.accountFormData.hasDirectDebitMandateCheckboxLabel"
      )
    },
    holderAddress: {
      street: {
        inputName: "bankAccount.holderAddress.street",
        labelName: t("onboardingPage.accountFormData.street"),
        rules: {
          required: t(
            "onboardingPage.accountFormData.streetRequiredErrorMessage"
          )
        }
      },
      houseNumber: {
        inputName: "bankAccount.holderAddress.houseNumber",
        labelName: t("onboardingPage.accountFormData.houseNumber"),
        rules: {
          required: t(
            "onboardingPage.accountFormData.houseNumberRequiredErrorMessage"
          )
        }
      },
      zip: {
        inputName: "bankAccount.holderAddress.zip",
        labelName: t("onboardingPage.accountFormData.zip"),
        rules: {
          required: t("onboardingPage.accountFormData.zipRequiredErrorMessage")
        }
      },
      city: {
        inputName: "bankAccount.holderAddress.city",
        labelName: t("onboardingPage.accountFormData.city"),
        rules: {
          required: t("onboardingPage.accountFormData.cityRequiredErrorMessage")
        }
      },
      country: {
        inputName: "bankAccount.holderAddress.country",
        labelName: t("onboardingPage.accountFormData.country"),
        selectItems: [
          {
            text: t("defaultSelect", { keyPrefix: "common" }),
            value: ""
          },
          ...possibleCountries()
        ],
        rules: {
          required: t(
            "onboardingPage.accountFormData.countryRequiredErrorMessage"
          )
        }
      }
    },
    accountNumber: {
      inputName: "bankAccount.accountNumber",
      labelName: t("onboardingPage.accountFormData.accountNumber"),
      rules: {
        required: t(
          "onboardingPage.accountFormData.accountNumberRequiredErrorMessage"
        )
      }
    },
    bankAddress: {
      bankName: {
        inputName: "bankAccount.bankAddress.name",
        labelName: t("onboardingPage.accountFormData.bankName"),
        rules: {
          required: t(
            "onboardingPage.accountFormData.bankNameRequiredErrorMessage"
          )
        }
      },
      street: {
        inputName: "bankAccount.bankAddress.street",
        labelName: t("onboardingPage.accountFormData.street"),
        rules: {
          required: t(
            "onboardingPage.accountFormData.streetRequiredErrorMessage"
          )
        }
      },
      houseNumber: {
        inputName: "bankAccount.bankAddress.houseNumber",
        labelName: t("onboardingPage.accountFormData.houseNumber"),
        rules: {
          required: t(
            "onboardingPage.accountFormData.houseNumberRequiredErrorMessage"
          )
        }
      },
      zip: {
        inputName: "bankAccount.bankAddress.zip",
        labelName: t("onboardingPage.accountFormData.zip"),
        rules: {
          required: t("onboardingPage.accountFormData.zipRequiredErrorMessage")
        }
      },
      city: {
        inputName: "bankAccount.bankAddress.city",
        labelName: t("onboardingPage.accountFormData.city"),
        rules: {
          required: t("onboardingPage.accountFormData.cityRequiredErrorMessage")
        }
      },
      country: {
        inputName: "bankAccount.bankAddress.country",
        labelName: t("onboardingPage.accountFormData.country"),
        selectItems: [
          {
            text: t("defaultSelect", { keyPrefix: "common" }),
            value: ""
          },
          ...possibleCountries()
        ],
        rules: {
          required: t(
            "onboardingPage.accountFormData.countryRequiredErrorMessage"
          )
        }
      }
    }
  }

  const bankAccountType = watch(
    bankAccountInfo.sepaType.inputName as "bankAccount.type"
  )

  const handleUpdateMerchant = (e: React.BaseSyntheticEvent) =>
    handleSubmit(
      async (formData) => {
        try {
          await updateMerchantData({
            updatedMerchantData: filterFormData(formData)
          })
          refetchMerchantData()
          enqueueSnackbar({
            variant: "success",
            message: t("accountPage.merchantUpdateSuccessSnackbar")
          })
        } catch (error) {
          if (error instanceof ApiError) {
            const response: ServiceError = await error.errorResponse.json()

            enqueueSnackbar({
              variant: "detailedSnackbar",
              message: t(response.code, { keyPrefix: "errorCodes" }),
              details: response.message,
              autoHideDuration: null
            })
          }
        }
      },
      (errors) => {
        console.error(errors)
      }
    )(e)

  return (
    <Grid
      item
      container
      padding={4}
      paddingRight={0}
      data-testid="bankAccountDataBox"
    >
      <Grid
        xs={12}
        item
        container
        alignItems="flex-start"
        spacing={2}
        paddingRight={{ xs: 4, lg: 0 }}
      >
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={bankAccountInfo.sepaType.inputName as "bankAccount.type"}
              render={({ field: { onChange, value } }) => (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => {
                          if (value === "SEPA") {
                            onChange("NON_SEPA")
                            unregister(
                              bankAccountInfo.iban
                                .inputName as "bankAccount.iban"
                            )
                          } else {
                            onChange("SEPA")
                          }
                        }}
                        checked={value === "SEPA" ? false : true}
                      />
                    }
                    data-testid="bankAccountTypeCheckbox"
                    label={
                      <Trans
                        i18nKey="onboardingPage.accountFormData.bankAccountTypeLabel"
                        components={{ strong: <strong /> }}
                      />
                    }
                  />
                </FormGroup>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              component="small"
              className="sepa-small"
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            >
              <ErrorOutline fontSize="small" sx={{ marginRight: 1 }} />
              {t("onboardingPage.accountFormData.bankAccountTypeInfoText")}
            </Box>
          </Grid>
        </Grid>

        <Grid item container xs={12} lg={6} spacing={2}>
          <Grid item xs={12}>
            <FormInputGroup
              disabled={true}
              inputName={bankAccountInfo.accountHolder.inputName}
              labelName={bankAccountInfo.accountHolder.labelName}
            />
          </Grid>

          <Grid item xs={12} md={6} data-testid="bicInputGroup">
            <FormInputGroup
              inputName={bankAccountInfo.bic.inputName}
              labelName={bankAccountInfo.bic.labelName}
              isLabelRequired={true}
              onInput={(value) => normalizeWhitespace(value).toUpperCase()}
              rules={bankAccountInfo.bic.rules}
            />
          </Grid>

          <Grid item xs={12} md={6} data-testid="ibanInputGroup">
            <FormInputGroup
              inputName={bankAccountInfo.iban.inputName}
              labelName={bankAccountInfo.iban.labelName}
              isLabelRequired={bankAccountType === "SEPA" ? true : false}
              onInput={(value) => normalizeWhitespace(value).toUpperCase()}
              rules={
                bankAccountType === "SEPA"
                  ? bankAccountInfo.iban.rules
                  : undefined
              }
            />
          </Grid>

          {getValues("bankAccount.type") !== "NON_SEPA" && (
            <>
              <Grid item xs={12} md={6} data-testid="creditorIdInputGroup">
                <FormInputGroup
                  disabled={true}
                  labelName={bankAccountInfo.creditorIdentifier.labelName}
                  inputName={bankAccountInfo.creditorIdentifier.inputName}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                data-testid="mandateReferenceInputGroup"
              >
                <FormInputGroup
                  disabled={true}
                  labelName={bankAccountInfo.mandateReference.labelName}
                  inputName={bankAccountInfo.mandateReference.inputName} //isn´t created in the backend yet --- empty input for now
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={
                    bankAccountInfo.hasDirectDebit
                      .inputName as "bankAccount.hasDirectDebitMandate"
                  }
                  render={({ field: { onChange, value } }) => (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox onChange={onChange} checked={value} />
                        }
                        label={bankAccountInfo.hasDirectDebit.labelName}
                        data-testid="hasDirectDebitCheckbox"
                      />
                    </FormGroup>
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      {bankAccountType === "NON_SEPA" && (
        <Grid
          xs={12}
          item
          container
          alignItems="flex-start"
          spacing={2}
          marginTop={2}
        >
          <Grid xs={12} data-testid="noIbanAccountInfoHeader">
            <Typography variant="h6">
              {t("onboardingPage.accountFormData.pageSubtitle.noIbanTitle")}
            </Typography>
          </Grid>

          {/* HOLDER ADDRESS */}
          <Grid
            item
            container
            xs={12}
            lg={6}
            spacing={2}
            paddingRight={{ xs: 4, lg: 0 }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  borderBottom: "none !important",
                  paddingLeft: "0 !important",
                  paddingBottom: "0 !important"
                }}
              >
                {t("onboardingPage.accountFormData.beneficiary")}
              </Typography>{" "}
            </Grid>
            <Grid item xs={12} lg={9}>
              <FormInputGroup
                inputName={bankAccountInfo.holderAddress.street.inputName}
                labelName={bankAccountInfo.holderAddress.street.labelName}
                isLabelRequired={true}
                rules={bankAccountInfo.holderAddress.street.rules}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <FormInputGroup
                inputName={bankAccountInfo.holderAddress.houseNumber.inputName}
                labelName={bankAccountInfo.holderAddress.houseNumber.labelName}
                isLabelRequired={true}
                rules={bankAccountInfo.holderAddress.houseNumber.rules}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <FormInputGroup
                inputName={bankAccountInfo.holderAddress.zip.inputName}
                labelName={bankAccountInfo.holderAddress.zip.labelName}
                isLabelRequired={true}
                rules={bankAccountInfo.holderAddress.zip.rules}
              />
            </Grid>

            <Grid item xs={12} lg={9}>
              <FormInputGroup
                inputName={bankAccountInfo.holderAddress.city.inputName}
                labelName={bankAccountInfo.holderAddress.city.labelName}
                isLabelRequired={true}
                rules={bankAccountInfo.holderAddress.city.rules}
              />
            </Grid>

            <Grid item xs={12}>
              <FormSelectGroup
                inputName={bankAccountInfo.holderAddress.country.inputName}
                labelName={bankAccountInfo.holderAddress.country.labelName}
                isLabelRequired={true}
                selectItems={bankAccountInfo.holderAddress.country.selectItems}
                rules={bankAccountInfo.holderAddress.country.rules}
              />
            </Grid>
          </Grid>

          {/* BANK INFO */}
          <Grid item container xs={12} lg={6} spacing={2} paddingRight={2}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  borderBottom: "none !important",
                  paddingLeft: "0 !important",
                  paddingBottom: "0 !important"
                }}
              >
                {t("onboardingPage.accountFormData.bankDetails")}
              </Typography>{" "}
            </Grid>

            <Grid item xs={12} lg={6}>
              <FormInputGroup
                inputName={bankAccountInfo.bankAddress.bankName.inputName}
                labelName={bankAccountInfo.bankAddress.bankName.labelName}
                isLabelRequired={true}
                rules={bankAccountInfo.bankAddress.bankName.rules}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <FormInputGroup
                inputName={bankAccountInfo.accountNumber.inputName}
                labelName={bankAccountInfo.accountNumber.labelName}
                isLabelRequired={true}
                rules={bankAccountInfo.accountNumber.rules}
              />
            </Grid>

            <Grid item xs={12} lg={9}>
              <FormInputGroup
                inputName={bankAccountInfo.bankAddress.street.inputName}
                labelName={bankAccountInfo.bankAddress.street.labelName}
                isLabelRequired={true}
                rules={bankAccountInfo.bankAddress.street.rules}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <FormInputGroup
                inputName={bankAccountInfo.bankAddress.houseNumber.inputName}
                labelName={bankAccountInfo.bankAddress.houseNumber.labelName}
                isLabelRequired={true}
                rules={bankAccountInfo.bankAddress.houseNumber.rules}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <FormInputGroup
                inputName={bankAccountInfo.bankAddress.zip.inputName}
                labelName={bankAccountInfo.bankAddress.zip.labelName}
                isLabelRequired={true}
                rules={bankAccountInfo.bankAddress.zip.rules}
              />
            </Grid>

            <Grid item xs={12} lg={9}>
              <FormInputGroup
                inputName={bankAccountInfo.bankAddress.city.inputName}
                labelName={bankAccountInfo.bankAddress.city.labelName}
                isLabelRequired={true}
                rules={bankAccountInfo.bankAddress.city.rules}
              />
            </Grid>

            <Grid item xs={12}>
              <FormSelectGroup
                inputName={bankAccountInfo.bankAddress.country.inputName}
                labelName={bankAccountInfo.bankAddress.country.labelName}
                isLabelRequired={true}
                selectItems={bankAccountInfo.bankAddress.country.selectItems}
                rules={bankAccountInfo.bankAddress.country.rules}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12} marginTop={2}>
        <Grid item xs={12} md={6} lg={2.5} xl={1.5}>
          <LoadingButton
            loading={isUpdatingMerchantData}
            fullWidth
            variant="contained"
            color="primary"
            onClick={(e) => handleUpdateMerchant(e)}
          >
            {t("common.saveBtn")} <KeyboardArrowRight />
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BankAccountDataBox
