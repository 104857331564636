import { ErrorOutline, KeyboardArrowRight } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Box, Grid } from "@mui/material"
import { useAtom } from "jotai"
import { useTranslation } from "react-i18next"
import { FormInputGroup, FormSelectGroup } from "src/features/onboarding"
import { updateMerchantDataAtom } from "src/shared/stores"

type GeneralInfoView = {
  merchantData?: MerchantRecord
  handleUpdateMerchant: (e: React.BaseSyntheticEvent) => Promise<void>
}

const GeneralInfoView = ({
  handleUpdateMerchant,
  merchantData
}: GeneralInfoView) => {
  const { t } = useTranslation("translation")
  const [{ isPending: isUpdatingMerchantData }] = useAtom(
    updateMerchantDataAtom
  )

  const commonInfo = {
    companyName: {
      inputName: "companyName",
      labelName: t("onboardingPage.merchantFormData.companyName")
    },
    companyNameAddition: {
      inputName: "companyNameAddition",
      labelName: t("onboardingPage.merchantFormData.companyNameAddition")
    },
    companySize: {
      inputName: "companySize",
      labelName: t("onboardingPage.merchantFormData.companySize"),
      possibleCompanySizes: [
        {
          text: t("common.defaultSelect"),
          value: ""
        },
        {
          text: t("onboardingPage.merchantFormData.companySizeSmallText"),
          value: "SMALL"
        },
        {
          text: t("onboardingPage.merchantFormData.companySizeMiddleText"),
          value: "MIDDLE"
        },
        {
          text: t("onboardingPage.merchantFormData.companySizeBigText"),
          value: "BIG"
        }
      ]
    },
    companyRevenue: {
      inputName: "companyRevenue",
      labelName: t("onboardingPage.merchantFormData.companyRevenue"),
      possibleCompanyRevenue: [
        {
          text: t("common.defaultSelect"),
          value: ""
        },
        {
          text: t("onboardingPage.merchantFormData.companyRevenueSmallText"),
          value: "SMALL"
        },
        {
          text: t("onboardingPage.merchantFormData.companyRevenueMiddleText"),
          value: "MIDDLE"
        },
        {
          text: t("onboardingPage.merchantFormData.companyRevenueBigText"),
          value: "BIG"
        }
      ]
    }
  }

  return (
    <Box data-testid="merchantGeneralInfoContainer" className="McpForm">
      <Grid container item spacing={2}>
        {!merchantData?.isSoleProprietorship && (
          <>
            <Grid item xs={12} data-testid="merchantCompanyNameInputGroup">
              <FormInputGroup
                disabled={true}
                inputName={commonInfo.companyName.inputName}
                labelName={commonInfo.companyName.labelName}
              />
              <Box
                component="small"
                className="sepa-small"
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                marginTop={1}
              >
                <ErrorOutline fontSize="small" sx={{ marginRight: 1 }} />
                {t("accountPage.disabledInputInfoText")}
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              data-testid="merchantCompanyNameAdditionInputGroup"
            >
              <FormInputGroup
                inputName={commonInfo.companyNameAddition.inputName}
                labelName={commonInfo.companyNameAddition.labelName}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={6}>
          <FormSelectGroup
            labelName={commonInfo.companySize.labelName}
            inputName={commonInfo.companySize.inputName}
            selectItems={commonInfo.companySize.possibleCompanySizes}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormSelectGroup
            labelName={commonInfo.companyRevenue.labelName}
            inputName={commonInfo.companyRevenue.inputName}
            selectItems={commonInfo.companyRevenue.possibleCompanyRevenue}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12} md={6} lg={2.5} xl={1.5}>
            <LoadingButton
              loading={isUpdatingMerchantData}
              fullWidth
              variant="contained"
              color="primary"
              onClick={(e) => handleUpdateMerchant(e)}
            >
              {t("common.saveBtn")} <KeyboardArrowRight />
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default GeneralInfoView
