const isInputValueEmptyString = (value?: string) => value?.trim() === ""

const filterFormData = (formData: MerchantRecord) => {
  const filteredFormData: MerchantRecord = structuredClone(formData)

  if (isInputValueEmptyString(formData.companySize)) {
    filteredFormData.companySize = undefined
  }

  if (isInputValueEmptyString(formData.companyRevenue)) {
    filteredFormData.companyRevenue = undefined
  }

  return filteredFormData
}

export default filterFormData
