import { KeyboardArrowRight } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material"
import { useAtom } from "jotai"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ApiError } from "src/shared/client"
import { merchantDataAtom, updateMerchantDataAtom } from "src/shared/stores"
import { languages } from "src/shared/variables"

const LanguageView = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "accountPage.company.languageTab"
  })
  const { enqueueSnackbar } = useSnackbar()

  const [{ data: merchantData, refetch: refetchMerchantData }] =
    useAtom(merchantDataAtom)
  const [
    { mutateAsync: updateMerchantData, isPending: isUpdatingMerchantData }
  ] = useAtom(updateMerchantDataAtom)

  const [companyLanguage, setCompanyLanguage] = useState<string>("de")

  const handleSetCompanyLanguage = (e: SelectChangeEvent) => {
    setCompanyLanguage(e.target.value)
  }

  const handleUpdateMerchant = async () => {
    if (merchantData && companyLanguage !== merchantData.companyLanguage) {
      try {
        await updateMerchantData({
          updatedMerchantData: {
            ...merchantData,
            companyLanguage: companyLanguage
          }
        })
        refetchMerchantData()
        enqueueSnackbar({
          variant: "success",
          message: t("merchantUpdateSuccessSnackbar", {
            keyPrefix: "accountPage"
          })
        })
      } catch (error) {
        if (error instanceof ApiError) {
          const response: ServiceError = await error.errorResponse.json()

          enqueueSnackbar({
            variant: "detailedSnackbar",
            message: t(response.code, { keyPrefix: "errorCodes" }),
            details: response.message,
            autoHideDuration: null
          })
        }
      }
    } else {
      enqueueSnackbar({
        variant: "success",
        message: t("merchantUpdateSuccessSnackbar", {
          keyPrefix: "accountPage"
        })
      })
    }
  }

  useEffect(() => {
    if (merchantData && merchantData.companyLanguage) {
      setCompanyLanguage(merchantData.companyLanguage)
    }
  }, [merchantData])

  return (
    <Box data-testid="merchantLanguageInfoContainer" className="McpForm">
      <Grid container item spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl>
            <FormLabel id="companyLanguage-label">
              {t("companyLanguage")}
            </FormLabel>
            <Select
              size="small"
              data-testid={"languageView-select"}
              aria-labelledby="companyLanguage-label"
              value={companyLanguage}
              onChange={handleSetCompanyLanguage}
              renderValue={(selectedValue) => {
                const selectedLanguage = languages.find(
                  (lng) => lng.lngCode === selectedValue
                )
                return (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      component="span"
                      className={`fi fi-${selectedLanguage?.flagCode}`}
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="body1">
                      {selectedLanguage?.nativeName}
                    </Typography>
                  </Box>
                )
              }}
            >
              {languages.map((lng) => (
                <MenuItem
                  key={lng.flagCode}
                  data-testid={`menu-item-${lng.lngCode}`}
                  value={lng.lngCode}
                >
                  <Box
                    component="span"
                    className={`fi fi-${lng.flagCode}`}
                    sx={{ mr: 1 }}
                  />
                  <Typography variant="body1">{lng.nativeName}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12} md={6} lg={2.5} xl={1.5}>
            <LoadingButton
              loading={isUpdatingMerchantData}
              fullWidth
              variant="contained"
              color="primary"
              data-testid="changeCompanyLanguageBtn"
              onClick={handleUpdateMerchant}
            >
              {t("saveBtn", { keyPrefix: "common" })} <KeyboardArrowRight />
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LanguageView
